import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Ensure proper imports
import './App.css';
import Home from './components/Home';
import TermsAndConditions from './components/TermsAndConditions'

function App() {
  return (
    <Router>
      
      <div className="App">
        

       <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<TermsAndConditions />} />
        </Routes>

      </div>
      
    </Router>
  );
}

export default App;