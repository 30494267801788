import React, { useState } from 'react'
import './TermsAndConditions.css';
import logo from './logo.png';


function TermsAndConditions() {
  const [activeTab, setActiveTab] = useState('our-services');

  const tabs = [
    { id: 'our-services', title: 'OUR SERVICES' },
    { id: 'use-of-services', title: 'USE OF SERVICES' },
    { id: 'prohibited-use', title: 'PROHIBITED USE' },
    { id: 'intellectual-property-rights', title: 'INTELLECTUAL PROPERTY RIGHTS' },
    { id: 'payment', title: 'PAYMENT' },
    { id: 'force-majeure', title: 'FORCE MAJEURE' },
    { id: 'third-party-websites-and-links', title: 'THIRD-PARTY WEBSITES AND LINKS' },
    { id: 'refunds', title: 'REFUNDS' },
    { id: 'governing-law', title: 'GOVERNING LAW' },
    { id: 'acceptance', title: 'ACCEPTANCE' },
    { id: 'modification', title: 'MODIFICATION' },
    { id: 'contact-us', title: 'CONTACT US' },
  ];

  const content = {
    
    'our-services': <p>  Welcome to Codevocado At Codevocado, we are dedicated to transforming your innovative ideas into reality through cutting-edge technology solutions. As a dynamic software company, we specialize in providing a wide range of services including website development, mobile app development, software testing, and skilled manpower provision.
      

    <h2>Our Services </h2>
    
    <p>  <span className="highlight">Website Development:</span>Website Development: Create a powerful online presence with our custom-designed, responsive websites. We ensure that your site is not only visually appealing but also optimized for performance and search engine visibility.</p>
    <p> <span className="highlight">Mobile App Development:</span>Mobile App Development: From concept to deployment, we develop high-performance mobile apps for both iOS and Android platforms. Our apps are designed to provide seamless user experiences and meet your specific business needs. c</p>
    <p> <span className="highlight"> ✔ Software Testing:</span> ✔ Software Testing: Ensure the reliability and quality of your software with our comprehensive testing services. Our expert testers employ the latest methodologies to detect and resolve issues, ensuring your product is bug-free and robust. </p>
    <p> <span className="highlight">Manpower Provider:</span>Manpower Provider: Access a pool of highly skilled professionals to augment your team and drive your projects to success. We provide top-tier talent to help you meet your business objectives efficiently.</p>
    </p>,

    'intellectual-property-rights': <p><span className="highlight">INTELLECTUAL PROPERTY RIGHTS</span>1.Website contents are proprietary to us.
    2.Unauthorized use may lead to action against you.</p>,
    'payment':<p><span className="highlight">PAYMENT</span>You agree to pay associated charges for availing Services.</p>,
    'use-of-services':<p><span className="highlight">USE OF SERVICES</span>1. Provide true, accurate, and complete information during registration.
    2. Be responsible for all acts done through your registered account.
    3. No warranty or guarantee is provided for accuracy, timeliness, performance, completeness, or suitability of information and materials.
    4. Use our Services at your own risk and discretion.</p>,
    'prohibited-use': <p><span className="highlight">PROHIBITED USE</span>No unlawful, illegal, or forbidden activities.  Comply with Indian and local laws.</p>,
    'force-majeure' :<p><span className="highlight">FORCE MAJEURE</span>Parties not liable for performance failure due to unforeseen events.</p>,
    'third-party-websites-and-links' :<p><span className="highlight">THIRD-PARTY WEBSITES AND LINKS</span>Governed by third-party website terms and policies.</p>,
    
    'refunds' :<p><span className="highlight">REFUNDS</span>1. Eligible for refund if Service not provided.
    2. Timelines: specific Service or our policies.
    </p>,
    'governing-law' :<p><span className="highlight">GOVERNING LAW</span>1. Governed by Indian laws.
    2. Exclusive jurisdiction: Deoria, Uttar Pradesh courts.</p>,
    'contact-us' :<p><span className="highlight">CONTACT US</span>Communicate concerns using contact information on our website<a href="(https://codevocado.in/)" target="_blank">(https://codevocado.in/)</a>.</p>,
    'acceptance' :<p><span className="highlight">ACCEPTANCE</span>By using our website and availing our Services, you agree to have read and accepted these Terms.</p>,
    'modification' :<p><span className="highlight">MODIFICATION</span>We reserve the right to modify these Terms at any time without notice. It's your responsibility to periodically review these Terms.</p>,

  };

  return (
    <div className="terms-and-conditions">
      <div className="logo-container">
      <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="tabs">
        {tabs.map((tab) => (
          <button
            key={(tab.id)}
            className={activeTab === (tab.id) ? 'active' : ''}
            onClick={() => setActiveTab((tab.id))}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="content">
        {content[activeTab]}
      </div>
    </div>
  );
}

export default TermsAndConditions;
