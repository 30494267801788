import React, { useState } from 'react';
import './home.css'; // Ensure this contains the necessary CSS
import CourseModal from '../Modal/Test'; // Assuming you have a Modal component
import farmerImage from '../assets/header.webp'; // Use a relevant image
import { Link } from 'react-router-dom'; // Import Link


const courses = [
  { id: 1, name: 'Certificate Course in Structured Query Language using MySQL', description: 'Learn the fundamentals of SQL and how to manage databases effectively.', price: 'Free', category: 'SQL' },
  { id: 2, name: 'Introduction to Python', description: 'Get started with Python programming, covering basic concepts and practical applications.', price: 'Free', category: 'Python' },
  { id: 3, name: 'Certificate Course in Basic Python', description: 'A comprehensive course to build your Python skills from scratch.', price: 'Free', category: 'Python' },
  { id: 4, name: 'Advanced Program in Cyber Security', description: 'Deep dive into advanced concepts in Cyber Security and threat management.', price: 'Free', category: 'Cyber Security' },
  { id: 5, name: 'Certificate Course in Linux Server Administration', description: 'Gain expertise in Linux server management and administration.', price: 'Free', category: 'Linux' },
  { id: 6, name: 'Certificate Course in Cyber Security', description: 'Understand the principles of Cyber Security and how to implement them effectively.', price: 'Free', category: 'Cyber Security' },
];

function Home() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const openModal = (course) => {
    setSelectedCourse(course);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedCourse(null);
  };

  return (
    <div className="course-page">
      {/* Farmer Image Banner */}
      <div className="banner">
        <img src={farmerImage} alt="Farmer supporting bamboo products" className="farmer-image" />
        <div className="banner-text">
        <h1>Enroll in Our Courses</h1>
        <p>Enhance your skills with our expert-led courses.</p>
      </div>
      </div>
       <h1>Available Courses</h1>
      <p>Explore our courses and enroll!</p>
      
         <Link to="/terms">Terms and Conditions</Link>

      <div className="course-grid">
        {courses.map((course) => (
          <div key={course.id} className="course-card">
            <h2>{course.name}</h2>
            <p>{course.description}</p>
            <p className="price">{course.price}</p>
            <button className="enroll-button" onClick={() => openModal(course)}>Get a Certificate</button>
          </div>
        ))}
      </div>

      <CourseModal 
        isOpen={modalOpen} 
        onClose={closeModal} 
        courseName={selectedCourse ? selectedCourse.name : ''} 
      />
    </div>
  );
}

export default Home;