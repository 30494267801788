import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import './test.css'; // Import CSS for modal styles
const apiBaseUrl = process.env.REACT_APP_COURSE_URL;

const Test = ({ isOpen, onClose, courseName }) => {
  const navigate = useNavigate(); // Initialize navigate for redirection
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    jobRole: '',
    comment: '',
  });

  sessionStorage.setItem("examName",courseName);

  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setError(''); // Reset error message on input change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make an API call to save data
      const response = await fetch(`${apiBaseUrl}/add_course_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to save data');
      }

      const result = await response.json(); // Parse the JSON response
      // console.log(result.status);
      // Check if the API indicates success (modify this based on your API response structure)
      if (result.status) {
        // Redirect to the take-test page
        navigate('/take-test'); // Use navigate instead of history.push
      } else {
        throw new Error(result.message || 'Unknown error occurred');
      }

    } catch (error) {
      setError(error.message); // Set error message to state
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Enroll in {courseName}</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="tel"
            name="mobile"
            placeholder="Your Mobile Number"
            value={formData.mobile}
            onChange={handleChange}
            required
          />
          <select name="jobRole" value={formData.jobRole} onChange={handleChange} required>
            <option value="" disabled>Select your Job Role</option>
            <option value="Software Engineer">Software Engineer</option>
            <option value="Student">Student</option>
            <option value="Other">Other</option>
          </select>
          <textarea
            name="comment"
            placeholder="Why are you taking this course?"
            value={formData.comment}
            onChange={handleChange}
            required
          />
          <button type="submit">Let's Start the Test</button>
        </form>
        <button className="close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Test;